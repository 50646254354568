export default [
  {
    path: '/dashboard',
    name: 'dashboard-partner',
    component: () => import('@/views/Partner/dashboard/dashboard.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/table',
    name: 'table',
    component: () => import('@/views/Partner/table.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/userLastPlay',
    name: 'userLastPlay',
    component: () => import('@/views/Partner/userLastPlay/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/commission/:id',
    name: 'commission',
    component: () => import('@/views/Partner/commission/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/commission_kr/:id',
    name: 'commission_kr',
    component: () => import('@/views/Partner/commission/view_kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/ref',
    name: 'ref',
    component: () => import('@/views/Partner/ref/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/calling',
    name: 'calling',
    component: () => import('@/views/Partner/calling/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit',
    name: 'deposit',
    component: () => import('@/views/Partner/deposit/deposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Resend',
    name: 'deposit-resend',
    component: () => import('@/views/Partner/deposit/deposit_resend.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-FromAdmin',
    name: 'deposit-fromadmin',
    component: () => import('@/views/Partner/deposit/deposit_fromAdmin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-FromAdmin-Korea',
    name: 'deposit-fromadmin-korea',
    component: () => import('@/views/Partner/deposit/deposit-kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Admin',
    name: 'deposit-admin',
    component: () => import('@/views/Partner/deposit/deposit_Admin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/DepositKR-Admin',
    name: 'deposit-kr-admin',
    component: () => import('@/views/Partner/deposit/deposit_Admin_kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw',
    name: 'withdraw',
    component: () => import('@/views/Partner/withdraw/withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Withdraw-kr',
    name: 'withdraw-kr',
    component: () => import('@/views/Partner/withdraw/withdraw_kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/movecredit',
    name: 'movecredit',
    component: () => import('@/views/Partner/movecredit/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member',
    name: 'member',
    component: () => import('@/views/Partner/member/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member-firstdep',
    name: 'memberfirstdep',
    component: () => import('@/views/Partner/member_firstdep/member.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/loguser',
    name: 'loguser',
    component: () => import('@/views/Partner/loguser/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/HistoryReport',
    name: 'HistoryReport',
    component: () => import('@/views/Partner/CRM/HistoryReport.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/DailyPlayTotal',
    name: 'DailyPlayTotal',
    component: () => import('@/views/Partner/CRM/DailyPlayTotal.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Member-add',
    name: 'member-add',
    component: () => import('@/views/Partner/member/add.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/zean-add',
    name: 'zean-add',
    component: () => import('@/views/Partner/member/add_zean.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event-bonus',
    name: 'event-bonus',
    component: () => import('@/views/Partner/event-bonus/index-bonus.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/history',
    name: 'event-history',
    component: () => import('@/views/Partner/event-bonus/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/point',
    name: 'event-point',
    component: () => import('@/views/Partner/event-bonus/event-point.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/event/history/redeem',
    name: 'history-redeem',
    component: () => import('@/views/Partner/event-bonus/history-redeem.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/dashboard-detail',
    name: 'dashboard-detail',
    component: () => import('@/views/Partner/dashboard-detail/dash_detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/deposit-withdraw',
    name: 'deposit-withdraw',
    component: () => import('@/views/Partner/dashboard-detail/dpo_with.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friend',
    component: () => import('@/views/Partner/invite-friend/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent/reportmember_register/:id',
    name: 'reportmember_register',
    component: () => import('@/views/Partner/invite-friend/reportmember_register.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent/reportmember/:id',
    name: 'reportmember',
    component: () => import('@/views/Partner/invite-friend/reportmember.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent/reportmember_kr/:id',
    name: 'reportmember_kr',
    component: () => import('@/views/Partner/invite-friend/reportmember_kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent/reportmember_totalplay/:id',
    name: 'reportmember_totalplay',
    component: () => import('@/views/Partner/invite-friend/reportmember_totalplay.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-friend',
    name: 'invite-friends',
    component: () => import('@/views/Partner/invite-friend/view_marketing.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms',
    name: 'send-sms',
    component: () => import('@/views/Partner/sendSMS/sms.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/thaibulksms',
    name: 'thaibulksms',
    component: () => import('@/views/Partner/thaibulksms/list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/thaibulksms-send',
    name: 'thaibulksms-send',
    component: () => import('@/views/Partner/thaibulksms/send.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail',
    name: 'invite-detail',
    component: () => import('@/views/Partner/invite-friend/detail.vue'),
    meta: {
      layout: 'full',
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/invite-detail/:id/:username',
    name: 'invite-detail-byadmin',
    component: () => import('@/views/Partner/invite-friend-old/detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-detail-all/:id',
    name: 'invite-detail-all-byadmin',
    component: () => import('@/views/Partner/invite-friend-old/detail_all.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-detail-all/:id',
    name: 'invite-detail-all',
    component: () => import('@/views/Partner/invite-friend/detail_all.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-add',
    name: 'zean-invite-add',
    component: () => import('@/views/Partner/invite-friend/add_by_zian.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/invite-friend-old',
    name: 'invite-friend-old',
    component: () => import('@/views/Partner/invite-friend-old/view.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-old-detail-old',
    name: 'invite-old-detail-old',
    component: () => import('@/views/Partner/invite-friend-old/detail.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/invite-old-detail/:id/:username',
    name: 'invite-old-detail-byadmin',
    component: () => import('@/views/Partner/invite-friend-old/detail.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/invite-old-detail-all/:id',
    name: 'invite-old-detail-all-byadmin',
    component: () => import('@/views/Partner/invite-friend-old/detail_all.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-old-detail-all/:id',
    name: 'invite-old-detail-all',
    component: () => import('@/views/Partner/invite-friend-old/detail_all.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/zean-invite-old-add',
    name: 'zean-invite-old-add',
    component: () => import('@/views/Partner/invite-friend-old/add_by_zian.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/lucky-wheel',
    name: 'lucky-wheel',
    component: () => import('@/views/Partner/wheel/wheel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // {
  //   path: '/wheel/setting',
  //   name: 'wheel-setting',
  //   component: () => import('@/views/Partner/wheel/setting.vue'),
  //   meta: {
  //     resource: 'User',
  //     action: 'read',
  //   },
  // },
  {
    path: '/wheel/history',
    name: 'wheel-history',
    component: () => import('@/views/Partner/wheel/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/Partner/invite-friend/user_profile.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  // thai lotto
  {
    path: '/thailotto/round',
    name: 'thailotto-round',
    component: () => import('@/views/Partner/thailotto/round.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/thailotto/round',
    name: 'thailotto-round',
    component: () => import('@/views/Partner/thailotto/round.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/thailotto/sold',
    name: 'thailotto-sold',
    component: () => import('@/views/Partner/thailotto/lotto-purchased.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/thailotto/reward',
    name: 'thailotto-reward',
    component: () => import('@/views/Partner/thailotto/lottery-list-reward.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Pre-Deposit-kr',
    name: 'pre-deposit-kr',
    component: () => import('@/views/Partner/deposit/deposit_pre.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Wait-Deposit-kr',
    name: 'wait-deposit-kr',
    component: () => import('@/views/Partner/deposit/deposit_waiting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/lucky-wheel',
    name: 'lucky-wheel',
    component: () => import('@/views/Partner/wheel/wheel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/wheel/setting/kr',
    name: 'wheel-setting-kr',
    component: () => import('@/views/Partner/wheelKR/ak_setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/wheel/setting',
    name: 'wheel-setting',
    component: () => import('@/views/Partner/wheel/ak_setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/wheel/history',
    name: 'wheel-history',
    component: () => import('@/views/Partner/wheel/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/card/setting',
    name: 'card-setting',
    component: () => import('@/views/Partner/card/ak_setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/card/history',
    name: 'card-history',
    component: () => import('@/views/Partner/card/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/dailylogin/setting',
    name: 'dailylogin-setting',
    component: () => import('@/views/Partner/dailylogin/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/dailyquests/setting',
    name: 'dailyquests-setting',
    component: () => import('@/views/Partner/dailyquests/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: () => import('@/views/Partner/invite-friend/user_profile.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Deposit-Withdraw/:id',
    name: 'Deposit-Withdraw',
    component: () => import('@/views/Partner/history/deposit_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/history/log',
    name: 'history',
    component: () => import('@/views/Partner/history/history.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/history/turnover/:id',
    name: 'history-turnover',
    component: () => import('@/views/Partner/history/history_turnover.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/history/UserEditlog',
    name: 'history-UserEditlog',
    component: () => import('@/views/Partner/history/history_EditUser.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/Edit-Data-zean/:id',
    name: 'Deposit-Withdraw-zean',
    component: () => import('@/views/Partner/invite-friend/EditData.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/user-detail-zean/:id',
    name: 'user-detail-zean',
    component: () => import('@/views/Partner/invite-friend/userdetail.vue'),
    meta: {
      resource: 'zean',
      action: 'read',
    },
  },
  {
    path: '/History-Play/:id',
    name: 'History-Play',
    component: () => import('@/views/Partner/history/play.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-deposit',
    name: 'sms-deposit',
    component: () => import('@/views/Partner/sms/smsDeposit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-withdraw',
    name: 'sms-withdraw',
    component: () => import('@/views/Partner/sms/smsWithdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-all',
    name: 'sms-all',
    component: () => import('@/views/Partner/sms/smsAll.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sms-otp',
    name: 'sms-otp',
    component: () => import('@/views/Partner/sms/otp.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/statement',
    name: 'statement',
    component: () => import('@/views/Partner/sms/statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/scb-connect',
    name: 'scb-connect',
    component: () => import('@/views/Partner/sms/scbConnect.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kplus-gen',
    name: 'kplus-gen',
    component: () => import('@/views/Partner/sms/kplus-gen.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kbank-Statement',
    name: 'kbank-statement',
    component: () => import('@/views/Partner/sms/kbankstatement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/krungsri-Statement',
    name: 'krungsri-statement',
    component: () => import('@/views/Partner/sms/krungsri_statement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/crypto-Statement',
    name: 'crypto-statement',
    component: () => import('@/views/Partner/sms/cryptostatement.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/kbank-live',
    name: 'kbank-live',
    component: () => import('@/views/Partner/sms/kbankLive.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/true-money',
    name: 'true-money',
    component: () => import('@/views/Partner/sms/trueMoney.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/waiting-dep',
    name: 'waiting-dep',
    component: () => import('@/views/Partner/sms/waitingDep.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/update-product',
    name: 'UpdateGame',
    component: () => import('@/views/Partner/update/games.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/Partner/admin/bank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/setting-bank',
    name: 'setting-bank',
    component: () => import('@/views/Partner/setting/bank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/auto-withdraw-scb',
    name: 'auto-withdraw-scb',
    component: () => import('@/views/Partner/admin/auto-withdraw-scb.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/withdraw-manual-list',
    name: 'withdraw-manual-list',
    component: () => import('@/views/Partner/admin/withdraw-manual-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/movebankcheck',
    name: 'movebankcheck',
    component: () => import('@/views/Partner/admin/movebankcheck.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/log-manual-withdraw',
    name: 'log-manual-withdraw',
    component: () => import('@/views/Partner/history/log_manual_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/log-manual-addcredit',
    name: 'log-manual-addcredit',
    component: () => import('@/views/Partner/history/log_manual_addcredit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/log-admin',
    name: 'log-admin',
    component: () => import('@/views/Partner/history/log_admin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/log-withdraw',
    name: 'log-withdraw',
    component: () => import('@/views/Partner/history/log_withdraw.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/auto-withdraw-kbank',
    name: 'auto-withdraw-kbank',
    component: () => import('@/views/Partner/admin/auto-withdraw-kbank.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/Partner/promotion/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/promotions',
    name: 'promotions',
    component: () => import('@/views/Partner/admin/promotionV2.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/channel',
    name: 'channel',
    component: () => import('@/views/Partner/admin/channel.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/admin-management',
    name: 'admin-management',
    component: () => import('@/views/Partner/admin/admin.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/admin-firstdep-check',
    name: 'admin-firstdep-check',
    component: () => import('@/views/Partner/admin/admin-firstdep-check.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/admin-agent-management',
    name: 'admin-agent-management',
    component: () => import('@/views/Partner/admin/admin_agent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/Partner/admin/setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/agent-list',
    name: 'agent-list',
    component: () => import('@/views/Partner/admin/agent-list.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/manage-agent/:id',
    name: 'manage-agent',
    component: () => import('@/views/Partner/admin/manage-agent.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sbobet-win-lose',
    name: 'report',
    component: () => import('@/views/Partner/sbobet/reports.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sbobet-setting',
    name: 'sbobet-setting',
    component: () => import('@/views/Partner/sbobet/sbo.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sbobet-table',
    name: 'sbobet-table',
    component: () => import('@/views/Partner/sbobet/table.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/scb-capcha/:ID',
    name: 'scb',
    component: () => import('@/views/Partner/scb.vue'),
    meta: {
      layout: 'full',
      // redirectIfLoggedIn: false,
      resource: 'Auth',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Partner/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/login-devteam',
    name: 'auth-login-devteam',
    component: () => import('@/views/Partner/login/Developer.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  // Setting games
  {
    path: '/customer-setting',
    name: 'customer-setting',
    component: () => import('@/views/Partner/setting/customer-setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/games-setting',
    name: 'games-setting',
    component: () => import('@/views/Partner/setting/game-setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/system-setting',
    name: 'system-setting',
    component: () => import('@/views/Partner/setting/system-setting.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/soccer-predictions',
    name: 'EventsSoccerPredictionIndex',
    component: () => import('@/views/Partner/events/soccer-prediction/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/lotto-predictions',
    name: 'EventsLottoPredictionIndex',
    component: () => import('@/views/Partner/events/lotto-prediction/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/reward',
    name: 'EventsReward',
    component: () => import('@/views/Partner/events/reward/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/system/ranking',
    name: 'SystemRanking',
    component: () => import('@/views/Partner/system/ranking/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/system/chip',
    name: 'SystemChip',
    component: () => import('@/views/Partner/system/chip/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/system/banners',
    name: 'SystemBanners',
    component: () => import('@/views/Partner/system/banners/index.vue'),
    meta: {
      resource: 'Admin',
      action: 'read',
    },
  },
  {
    path: '/system/coupon',
    name: 'SystemCoupon',
    component: () => import('@/views/Partner/system/coupon/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reports/deposits',
    name: 'ReportsDeposits',
    component: () => import('@/views/Partner/reports/deposits/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reports/withdraws',
    name: 'ReportsWithdraws',
    component: () => import('@/views/Partner/reports/withdraws/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reports/depwit',
    name: 'ReportsDepWit',
    component: () => import('@/views/Partner/reports/depwit/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reports/depwit_kr',
    name: 'ReportsDepWit_kr',
    component: () => import('@/views/Partner/reports/depwit/index_kr.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/logs/banks',
    name: 'LogsBanks',
    component: () => import('@/views/Partner/logs/banks/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/logs/exports',
    name: 'LogExports',
    component: () => import('@/views/Partner/logs/exports/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/logs/deposit-withdraw',
    name: 'LogsDepositWithdraw',
    component: () => import('@/views/Partner/logs/deposit-withdraw/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: () => import('@/views/Partner/announcements/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/daily-quests',
    name: 'EventsDailyQuests',
    component: () => import('@/views/Partner/events/daily-quests/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/soccer/next-match',
    name: 'EventsNextMatch',
    component: () => import('@/views/Partner/events/soccer/next-match/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/soccer/next-match/create',
    name: 'EventsNextMatchCreate',
    component: () => import('@/views/Partner/events/soccer/next-match/create.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/events/soccer/next-match/:id/edit',
    name: 'EventsNextMatchEdit',
    component: () => import('@/views/Partner/events/soccer/next-match/edit.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bonus/cashback',
    name: 'BonusCashback',
    component: () => import('@/views/Partner/bonus/cashback/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/outstanding-admin',
    name: 'OutstandingAdmin',
    component: () => import('@/views/Partner/outstanding-admin/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/reviews',
    name: 'reviews',
    component: () => import('@/views/Partner/reviews/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/no-deposit-members',
    name: 'NoDepositMembers',
    component: () => import('@/views/Partner/member/no_deposit_members.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/bonus',
    name: 'Bonus',
    component: () => import('@/views/Partner/bonus/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/games/slots',
    name: 'GameSlots',
    component: () => import('@/views/Partner/games/slots/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/games/bonus-time',
    name: 'GameBonusTime',
    component: () => import('@/views/Partner/games/bonus-time/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/banners',
    name: 'Banners',
    component: () => import('@/views/Partner/banners/index.vue'),
    meta: {
      resource: 'User',
      action: 'read',
    },
  },
]
